<template>
	<div class="bg-Hight">
		<!-- 筛选 -->
		<div class="crud">
			<ul class="crud-list">
				<li>
					<el-button size="mini" @click="getList">刷新</el-button>
				</li>
				<li v-permission="['roleadd']">
					<el-button @click="add('新增')" class="el-white" color="#488bff" style="color: white" size="mini">新增
					</el-button>
				</li>
				<li v-permission="['roledele']">
					<el-button @click="alldele" size="mini" class="el-white" type="danger">删除</el-button>
				</li>
			</ul>
		</div>
		<!-- 表格列表 -->
		<div class="table">
			<el-table element-loading-text="加载中..." v-loading="tableloading" :max-height="maxheight" border
				:data="tableData" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="100" />
				<el-table-column type="index" label="序号" width="100" />
				<el-table-column property="name" label="角色名称" />
				<el-table-column property="status" label="启用/禁用" v-if="hiderole">
					<template #default="scope">
						<el-tooltip :content="scope.row.status==1?'已启用':'已禁用'" placement="top">
							<el-switch :active-value="1" :inactive-value="0" v-model="scope.row.status"
								@change="ChangeSwitch(scope.row.id,scope.row.status)" active-color="#488bff"
								inactive-color="#ff4949" />
						</el-tooltip>
					</template>
				</el-table-column>
				<el-table-column sortable property="create_time" label="创建时间" />
				<el-table-column property="address" label="操作">
					<template #default="scope">
						<el-button v-permission="['roleedits']" @click="add('编辑',scope.row.id)" size="mini">编辑</el-button>
						<el-button v-permission="['roledele']" size="mini" @click="dele(scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--分页 -->
			<div class="pages">
				<el-pagination background v-model="form.page" :page-sizes="[15, 20, 30, 40,50,100]" :page-size="15"
					layout="sizes, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
					@current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>
		<!-- 新增弹窗 -->
		<el-dialog draggable width="40%" :destroy-on-close="true" v-model="outerVisible" :title="title">
			<Form ref="form" :title="title" :mid="mid" @SubmitForm="getList" @close="outerVisible = false"></Form>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="submitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import Form from './form.vue'
	import {
		request,
		api
	} from "@/api/role";
	export default {
		name: 'workhall',
		components: {
			Form
		},
		data() {
			return {
				tableloading: false,
				hiderole: true,
				maxheight: document.documentElement.clientHeight - 232 + 'px',
				mid: '',
				outerVisible: false,
				title: '新增',
				form: {
					page: 1,
					limit: 15,
				},
				total: 0,
				tableData: [],
				idlist: [],
				btnlist: []
			}
		},
		created() {
			this.getList()
		},
		computed: {
			btnlist() {
				return this.$store.state.selectlist
			},
			hiderole() {
				var leg = this.$store.state.selectlist.some(item => item.typename === 'groupattr')
				if (leg == true) {
					return true
				} else {
					return false
				}
			}
		},
		methods: {
			// 分页
			handleSizeChange(e) {
				this.form.limit = e
				this.getList()
			},
			// 分页
			handleCurrentChange(e) {
				this.form.page = e
				this.getList()
			},
			// 表格全选
			handleSelectionChange(e) {
				this.idlist = []
				e.forEach(item => {
					this.idlist.push(item.id)
				})
			},
			// 新增、编辑提交
			submitForm() {
				this.$refs.form.SubmitForm()
			},
			// 清空
			reset() {
				this.form.page = 1
				this.getList()
			},
			// 禁用状态
			ChangeSwitch(id, state) {
				this.$http.post(api.attr, {
						id: id,
						field: 'status',
						value: state
					})
					.then((res) => {
						ElMessage({
							message: res.msg,
							type: 'success',
						})
						this.getList()
					})
			},
			// 获取列表
			getList() {
				this.tableloading = true
				this.$http.post(api.rolelist, this.form)
					.then((res) => {
						this.tableData = res.data.list
						this.total = res.count
						this.tableloading = false
					})
			},
			// 新增
			add(val, id) {
				if (val == '编辑') {
					this.mid = id
				}
				this.title = val
				this.outerVisible = !this.outerVisible
			},
			// 批量删除
			alldele() {
				if (this.idlist.length == 0) {
					ElMessage({
						message: '请选择需要删除数据',
						type: 'warning',
					})
				} else {
					ElMessageBox.confirm(
							'此操作将永久删除?',
							'提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning',
							}
						)
						.then(() => {
							this.$http.post(api.dele, {
									id: this.idlist.join(',')
								})
								.then((res) => {
									if (res.code == 200) {
										ElMessage({
											type: 'success',
											message: '删除成功',
										})
										this.form.page = 1
										this.getList()
									}
								})
						})
						.catch(() => {
							// 取消
						})
				}
			},
			// 删除
			dele(id) {
				ElMessageBox.confirm(
						'此操作将永久删除?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.dele, {
								id: id
							})
							.then((res) => {
								if (res.code == 200) {
									ElMessage({
										type: 'success',
										message: '删除成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
					.catch(() => {
						// 取消
					})
			}
		}
	}
</script>
