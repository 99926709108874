import request from './request'

const api = {
	// 获取角色列表
  rolelist: '/system/Role/getPageList',
  // 启用禁用角色
  attr: '/group/attr',
  // 树形结构菜单（角色权限）
  treeMenu: '/system/Menu/getTreeList',
  // 添加角色
  add: '/system/Role/create',
  // 编辑角色
  edit: '/system/Role/update',
  // 获取角色信息
  info: '/system/Role/read',
  // 删除角色
  dele: '/system/Role/delete',
  
}

export { request, api }
